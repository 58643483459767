import React from 'react';
import { Block } from 'components/atoms';
import withTheme from 'hocs/withTheme';
import {
  Header,
  PumpkinBenefit,
  PumpkinFeature,
  ParticipatingStores,
  Footer,
  DownloadSection
} from 'components/molecules';
import { PrivilegeSection } from 'components/organisms';
import { UserHeader } from '../components/molecules';
import SEO from '../components/seo';

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Block>
        <SEO />
        <Header />
        <UserHeader />
        <PrivilegeSection />
        <PumpkinBenefit />
        <PumpkinFeature state={'user'} />
        <ParticipatingStores />
        <DownloadSection />
        <Footer />
      </Block>
    );
  }
}

export default withTheme()(MainPage);
